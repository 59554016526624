// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD5NqR63UZoafymSz62b89_HACmdDvXL9o",
    authDomain: "terblanchetroue-4ddcf.firebaseapp.com",
    projectId: "terblanchetroue-4ddcf",
    storageBucket: "terblanchetroue-4ddcf.appspot.com",
    messagingSenderId: "526838392727",
    appId: "1:526838392727:web:22535f0efeb560886446d2"
  }
};
